<template>
  <div class="rightLink">
    <div class="bottomDiv" style="padding:0px 25px;height:750px">
      <div class="title" style="margin-bottom: 5px;"><i class="glyphicon glyphicon-user"></i> 系统用户列表</div>
      <div>
        <!-- <el-button class="buttonPri" style="width:160px" @click="openAddUser" size="small"
          ><i class="glyphicon glyphicon-plus"></i> 新增个人运营用户</el-button
        >  -->
        <!-- <el-button class="buttonPri" style="width:160px" @click="openAddUser2" size="small"
          ><i class="glyphicon glyphicon-plus"></i> 新增企业运营用户</el-button
        > -->
        <el-button class="buttonPri" style="width:160px" @click="openAddUser3" size="small"
          ><i class="glyphicon glyphicon-plus"></i>新增运营用户</el-button
        >
      </div>
      <el-table
        header-align="center"
        align="center"
        v-loading="loading"
        :data="tableData"
        style="width: 100%;"
        height="630px"
        stripe
        size="medium"
        :header-row-style="{ color: '#333' }"
      >
        <el-table-column prop="id" label="序号" type="index" width="80" />
        <el-table-column prop="username" label="用户名称" min-width="60" />
        <!-- <el-table-column prop="password" label="登录密码" min-width="180" /> -->
        <el-table-column prop="is_active" label="系统用户类型" min-width="80">
          <template #default="scope">
            {{ scope.row.is_active == true ? "正常" : "禁用" }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱地址" min-width="110" />
        <el-table-column prop="cellphone" label="联系电话" min-width="80" />
        <el-table-column prop="id_number" label="身份证号" min-width="110" />
        <el-table-column prop="is_active" label="系统用户状态" min-width="80">
          <template #default="scope">
            {{ scope.row.is_active == true ? "正常" : "禁用" }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" min-width="80">
          <template #default="scope">
            <span v-if="scope.row.status === 0">审核中</span>
            <span v-else-if="scope.row.status == 1">已完成</span>
            <span v-else-if="scope.row.status == 2">编辑中</span>
            <span v-else-if="scope.row.status == 3">已驳回</span>
            <span v-else-if="scope.row.status == 4">待账户验证</span>
            <span v-else-if="scope.row.status == 5">待签约</span>
            <span v-else-if="scope.row.status == 6">开通权限中</span>
            <span v-else-if="scope.row.status == 7">已作废</span>
          </template>
        </el-table-column>
        
        <el-table-column label="操作" min-width="210" fixed="right">
          <template #default="scope">
            <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑1</el-button
            > -->
            <!-- <el-button v-show="!scope.row.person_or_enterprise" size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            > -->
            <el-button
              size="mini"
              @click="openAddUser2(scope.row)"
              ><i class="glyphicon glyphicon-plus"></i> 认证</el-button
            >
            <el-button size="mini" 
            @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            >
            <!-- <el-button v-if="!scope.row.id_number && scope.row.business_license_number " size="mini" @click="handleEdit2(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            > -->
            <!-- <el-button v-if="!scope.row.id_number && !scope.row.business_license_number" size="mini" @click="handleEdit3(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            > -->
            <el-button v-show="scope.row.is_active == true" size="mini" @click="disable(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-remove"></i> 禁用</el-button
            >

            <el-button v-show="scope.row.is_active == false" size="mini" @click="enable(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-ok"></i> 启用</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-trash"></i> 删除</el-button
            >
           
          </template>
        </el-table-column>
      </el-table>
       <!-- 新增个人用户 -->
      <div v-dialogdrag>
        <el-dialog
        v-model="addRoleDiaLogFlag"
        :title="DiaLogFlagTitle"
        :before-close="handleClose"
        destroy-on-close
        width="50%"
      >
        <el-form
          :model="editUser"
          label-position="right"
          label-width="130px"
          :rules="editUserRules"
          ref="editUserDialog"
        >
        <el-row :gutter="20">
          <el-col :span="3"></el-col>
          <el-col :span="16">
            <el-form-item label="用户名" prop="username">
              <el-input clearable v-model="editUser.username" :disabled="isEdit ? true : false"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input clearable v-model="editUser.email"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="id_number">
              <el-input clearable v-model="editUser.id_number"></el-input>
            </el-form-item>
            <el-form-item label="联系电话"  prop="cellphone">
              <el-input clearable v-model="editUser.cellphone"></el-input>
            </el-form-item>
            <!-- <el-form-item label="商户号"  prop="mchid">
              <el-input clearable v-model="editUser.mchid" ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="支付类型:">
              <el-radio-group v-model="radio" @change="handleChange">
                <el-radio :label="0" name="0">微信支付</el-radio>
                <el-radio :label="1" name="1">三方支付</el-radio>
              </el-radio-group>
            </el-form-item> -->
          </el-col>
        </el-row>
        
        
      </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button type="primary" :disabled=" !editUser.email || !editUser.email.trim() || !editUser.id_number ||!editUser.id_number.trim() || !editUser.cellphone || !editUser.cellphone.trim()" @click="submitEditUser"
              >确认</el-button
            >
          </span>
        </template>
      
      </el-dialog>
    </div>
    <!-- 商户入驻认证 -->
    <div v-dialogdrag>
      <el-dialog
        v-model="addRoleDiaLogFlag2"
        :title="DiaLogFlagTitle2"
        :before-close="handleClose"
        destroy-on-close
        width="62%"
      >
        <el-form
          :model="editUser2"
          label-position="right"
          label-width="180px"
          :rules="editUserRules"
          ref="editUserDialog1"
          style="width: 85%; margin: 0 auto;"
        >
        <el-row>
          <el-col :span="12">
          <el-form-item label="运营商登陆账号" prop="username">
            <el-input clearable v-model="editUser2.username" :disabled="isEdit ? true : false" ></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <!-- <el-form-item label="密码" prop="password">
            <el-input
              clearable
              v-model="editUser2.password"
              show-password
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="法人姓名">
            <el-input clearable v-model="editUser2.legal_person"></el-input>
          </el-form-item> -->
          <el-form-item label="商户姓名" prop="contact_name" >
            <el-input clearable v-model="editUser2.contact_name" ></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
          <el-form-item label="联系电话"  prop="mobile_phone">
            <el-input clearable v-model="editUser2.mobile_phone" ></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="邮箱" prop="contact_email">
            <el-input clearable v-model="editUser2.contact_email" ></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row>
        <el-col :span="12">  
          <el-form-item label="证件类型:" prop="id_doc_type">
            <el-select
              style="width: 100%"
              v-model="editUser2.id_doc_type"
            >
              <el-option
                v-for="doc in docList"
                :key="doc.value"
                :value="doc.value"
                :label="doc.label"
              />
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="身份证号" prop="contact_id_number">
            <el-input clearable v-model="editUser2.contact_id_number "></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="商户名称" prop="merchant_name">
            <el-input clearable v-model="editUser2.merchant_name "></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="商户简称" prop="merchant_shortname">
            <el-input clearable v-model="editUser2.merchant_shortname "></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="经营者或者法人姓名" prop="legal_person" style="white-space: nowrap;">
            <el-input clearable v-model="editUser2.legal_person "></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="统一社会信用代码" prop="license_number" style="white-space: nowrap; ">
            <el-input clearable v-model="editUser2.license_number"></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="经营场景" prop="sales_scenes_type">
            <el-select
              style="width: 100%"
              v-model="editUser2.sales_scenes_type"
            >
              <el-option
                v-for="sale in saleList"
                :key="sale.value"
                :value="sale.value"
                :label="sale.label"
              />
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="主体类型:" prop="subject_type">
            <el-select
              style="width: 100%"
              v-model="editUser2.subject_type"
            >
              <el-option
                v-for="subject1 in subjectList"
                :key="subject1.value"
                :value="subject1.value"
                :label="subject1.label"
              />
            </el-select>
          </el-form-item>
          </el-col>
          </el-row>

          <el-row>
          <el-col :span="12">
          <el-form-item label="结算规则" prop="settlement_id">
            <el-select
              style="width: 100%"
              v-model="editUser2.settlement_id"
            >
              <el-option
                v-for="settlement in settlementList"
                :key="settlement.value"
                :value="settlement.value"
                :label="settlement.label"
              />
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="账户类型" prop="bank_account_type">
            <el-select
              style="width: 100%"
              v-model="editUser2.bank_account_type"
            >
              <el-option
                v-for="bank1 in bankList"
                :key="bank1.value"
                :value="bank1.value"
                :label="bank1.label"
              />
            </el-select>
          </el-form-item>
          </el-col>
          </el-row>

          <el-row>
          <el-col :span="12">
          <el-form-item label="开户名称" prop="account_name" >
            <el-input clearable v-model="editUser2.account_name " ></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="开户银行" prop="account_bank">
            <el-input clearable v-model="editUser2.account_bank "></el-input>
          </el-form-item>
          </el-col>
          </el-row>

          <el-row>
          <el-col :span="12">
          <el-form-item label="开户省市编码" prop="bank_address_code">
            <el-input clearable v-model="editUser2.bank_address_code "></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="开户银行卡号" prop="account_number">
            <el-input clearable v-model="editUser2.account_number "></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="开户银行全称" prop="bank_branch_id" >
            <el-input clearable v-model="editUser2.bank_branch_id "></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="法人居住地址" prop="id_card_address">
            <el-input clearable v-model="editUser2.id_card_address "></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="法人/受益人" prop="id_card_name" >
            <el-input clearable v-model="editUser2.id_card_name "></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="法人身份证号" prop="id_card_number">
            <el-input clearable v-model="editUser2.id_card_number "></el-input>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
          <el-form-item label="身份证有效时间" prop="card_period_begin" >
            <el-date-picker  
              v-model="editUser2.card_period_begin"  
              type="date"  
              placeholder="请选择时间"  
              :size="large"  
              format="YYYY-MM-DD" 
              value-format="YYYY-MM-DD" 
              style="width: 100%;"
            ></el-date-picker>  
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="身份证有效结束时间" prop="card_period_end">
            <el-date-picker  
              v-model="editUser2.card_period_end"  
              type="date"  
              placeholder="请选择时间"  
              :size="large"
              format="YYYY-MM-DD"  
              value-format="YYYY-MM-DD" 
              style="width: 100%;"
            ></el-date-picker>  
          </el-form-item>
          </el-col>
          </el-row>
          <el-form-item label="超级管理员证件类型" prop="contact_id_doc_type">
            <el-select
              style="width: 100%"
              v-model="editUser2.contact_id_doc_type"
            >
              <el-option
                v-for="doc in docList"
                :key="doc.value"
                :value="doc.value"
                :label="doc.label"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="联系地址">
            <el-input clearable v-model="editUser2.yyzzid"></el-input>
          </el-form-item> -->
            <!-- <el-form-item label="支付类型:">
                <el-radio-group v-model="radio" @change="handleChange">
                  <el-radio :label="0" name="0">微信支付</el-radio>
                  <el-radio :label="1" name="1">三方支付</el-radio>
                </el-radio-group>
              </el-form-item> -->

          <el-row>
            <el-col :span="12"> 
            <el-form-item label="上传身份证人像面照片:" prop="reverse_of_id_card">

            <el-upload

              list-type="picture-card"
              ref="uploadFile3"
              action="/"
              :limit="1"
              :auto-upload="false"
              :on-remove="handleRemove"
              :file-list="fileList3"
              :on-exceed="handleExceed"
              :http-request="onBeforeAppendFromData4"
              :before-upload="onBeforeRuls"
              
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <span style="color:red;">限jpg或png</span>
            <el-dialog v-model="reverse_of_id_card">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
          </el-form-item>
          </el-col>  
          <el-col :span="12"> 
          <el-form-item label="上传身份证国徽面照片:" prop="the_front_of_id_card">
                  
          <el-upload
            list-type="picture-card"
            ref="uploadFile4"
            action="/"
            :limit="1"
            :multiple="false"
            :auto-upload="false"
            :on-remove="handleRemove"
            :file-list="fileList4"
            :on-exceed="handleExceed"
            :http-request="onBeforeAppendFromData5"
            :before-upload="onBeforeRuls"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <span style="color:red;">限jpg或png</span>
          <el-dialog v-model="the_front_of_id_card">
            <img w-full :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
        </el-form-item>
        </el-col> 
        </el-row>
        <el-row>
            <el-col :span="12">    
            <el-form-item label="上传营业执照照片:" prop="business_license_img">
                <el-upload
                  list-type="picture-card"
                  ref="uploadFile2"
                  action="/"
                  :limit="1"
                  :multiple="false"
                  :auto-upload="false"
                  :on-remove="handleRemove"
                  :file-list="fileList2"
                  :on-exceed="handleExceed"
                  :http-request="onBeforeAppendFromData3"
                  :before-upload="onBeforeRuls"
                >
                  <el-icon><Plus /></el-icon>
                </el-upload>
                <span style="color:red;">限jpg或png</span>

                <el-dialog v-model="business_license_img">
                  <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
              </el-form-item>
            </el-col>  
          <el-col :span="12"> 
          <el-form-item label="授权书图片上传:" prop="business_authorization_letter">
                  
          <el-upload
            list-type="picture-card"
            ref="uploadFile5"
            action="/"
            :limit="1"
            :multiple="false"
            :auto-upload="false"
            :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview"
            :file-list="fileList5"
            :on-exceed="handleExceed"
            :http-request="onBeforeAppendFromData6"
            :before-upload="onBeforeRuls"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <span style="color:red;">限jpg或png</span>
          <el-dialog v-model="business_authorization_letter">
            <img w-full :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
        </el-form-item>
        </el-col> 
        </el-row>
          
        </el-form>
          
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button type="primary" @click="submitEditUser2"
              >确认</el-button
            >
          </span>
        </template>
      </el-dialog>


       <!-- 快速新增运营用户 -->
    <div v-dialogdrag>
      <el-dialog
        v-model="addRoleDiaLogFlag3"
        :title="DiaLogFlagTitle3"
        :before-close="handleClose"
        destroy-on-close
        width="50%"
      >
        <el-form
          :model="editUser3"
          label-position="right"
          label-width="130px"
          ref="editUserDialog2"
          :rules="editUserRules"
          style="width: 80%; margin: 0 auto;"
        >
            <el-form-item label="用户名" prop="username">
            <el-input clearable v-model="editUser3.username" :disabled="isEdit ? true : false"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              clearable
              v-model="editUser3.password"
              show-password
              :disabled="isEdit ? true : false"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="邮箱" prop="email" v-if="isAdd == '0'">
          <el-input clearable v-model="editUser3.email"></el-input>
          </el-form-item> -->
          <el-form-item label="身份证号" prop="id_number" v-if="isAdd == '0'">
          <el-input clearable v-model="editUser3.id_number"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="cellphone">
          <el-input clearable v-model="editUser3.cellphone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="商户号" prop="mchid" v-if="isAdd == '0'">
          <el-input clearable v-model="editUser3.mchid"></el-input>
          </el-form-item> -->
          <el-form-item label="角色" prop="roles_id" v-if="isAdd == '1'">
            <el-select v-model="editUser3.roles_id" style="width:100%">
              <el-option
                v-for="(item, index) in roleList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="支付类型:">
            <el-radio-group v-model="radio" @change="handleChange">
              <el-radio :label="0" name="0">微信支付</el-radio>
              <el-radio :label="1" name="1">三方支付</el-radio>
            </el-radio-group>
          </el-form-item> -->
        
        </el-form>
          
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button type="primary" @click="submitEditUser3"
              >确认</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    </div>
    </div>
    
  </div>
</template>

<style></style>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { add_superuser1,sel_superuser,edit_superuser,delUser,prohibit_user,active_user,commercial_tenant,roles } from "@/request/api";
import { ElMessageBox, ElMessage, ElUpload } from "element-plus";

// import JSEncrypt from "jsencrypt";
export default {
  name: "sysConfiguser",
  setup() {
    // ref 弹框中的from
    const editUserDialog = ref(null);
    const editUserDialog1 = ref(null);
    const editUserDialog2 = ref(null);
    const data = reactive({
      isEdit: false,
      page: 1,
      total: 0,
      current_page: 10,
      tableData: [], // 表格数据容器
      DiaLogFlagTitle: "", // 弹框title
      addRoleDiaLogFlag: false, // 角色添加弹框显示
      isAdd: "", // 1 是添加 0 是编辑
      DiaLogFlagTitle2: "", // 弹框title
      addRoleDiaLogFlag2: false, // 角色添加弹框显示
      // isAdd2: "", // 1 是添加 0 是编辑
      loading: false,
      DiaLogFlagTitle3: "", // 弹框title
      addRoleDiaLogFlag3: false, // 角色添加弹框显示
      // 搜索
      searchData: {
        keyword: "",
      },
      docList: [
        {
          value: 1,
          label:"中国大陆居民-身份证",
        },
        {
          value: 2,
          label:"其他国家或地区居民-护照",
        },
        {
          value: 3,
          label:"中国香港居民-来往内地通行证",
        },
        {
          value: 4,
          label:"中国澳门居民-来往内地通行证",
        },
        {
          value: 5,
          label:"中国台湾居民-来往大陆通行证",
        },
        {
          value: 6,
          label:"外国人居留证",
        },
        {
          value: 7,
          label:"港澳居民证",
        },
        {
          value: 8,
          label:"台湾居民证",
        },
      ],
      doc: "",
      subjectList: [
        {
          value: 1,
          label:"个人",
        },
        {
          value: 2,
          label:"企业",
        },
        {
          value: 3,
          label:"政府",
        },
        {
          value: 4,
          label:"医疗",
        },
        {
          value: 5,
          label:"社会团体/农村组织",
        },
      ],
      subject1: "",
      saleList: [
        {
          value: 1,
          label:"线下场所",
        },
        {
          value: 2,
          label:"公众号",
        },
        {
          value: 3,
          label:"小程序",
        },
        {
          value: 4,
          label:"互联网网站",
        },
        {
          value: 5,
          label:"App",
        },
        {
          value: 6,
          label:"企业微信",
        },
      ],
      sale: "",
      settlementList: [
        {
          value: 1,
          label:"个体户",
        },
        {
          value: 2,
          label:"企业",
        },
      ],
      settlemen: "",
      bankList: [
        {
          value: 1,
          label:"对公账户",
        },
        {
          value: 2,
          label:"个人银行卡",
        },
      ],
      bank1: "",
      // radio: 0,
      // 用户的添加与编辑数据容器
      editUser: {
        username: "",
        password: "",
        email: "",
        id_number:"",
        cellphone: "",
        is_active: true,
        // mchid: "",
        business_license_number: '',
      },
      editUser2: {
        
      },
      editUser3: {
        username: "",
        password: "",
        email: "",
        // mchid: "",
        cellphone: "",
        // radio: ""
      },
      // 用户添加和编辑的表单校验
      editUserRules: {
        realname: [
          {
            required: true,
            message: "使用人不能为空",
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        id_number: [
          {
            required: true,
            message: "身份证号不能为空",
            trigger: "blur",
          },
        ],
        contact_id_number: [
          {
            required: true,
            message: "身份证号不能为空",
            trigger: "blur",
          },
        ],
        id_doc_type: [
          {
            required: true,
            message: "证件类型不能为空",
            trigger: "blur",
          },
        ],
        subject_type: [
          {
            required: true,
            message: "主体类型不能为空",
            trigger: "blur",
          },
        ],
        license_number: [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur",
          },
        ],
        merchant_name: [
          {
            required: true,
            message: "商户名称不能为空",
            trigger: "blur",
          },
        ],
        legal_person: [
          {
            required: true,
            message: "个体户经营者或者法人名字不能为空",
            trigger: "blur",
          },
        ],
        cellphone: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "商户姓名不能为空",
            trigger: "blur",
          },
        ],
        mobile_phone: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
        ],
        contact_email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
        ],
        // mchid: [
        //   {
        //     required: true,
        //     message: "商户号不能为空",
        //     trigger: "blur",
        //   },
        // ],
        yyzzid: [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur",
          },
        ],
        firm_type: [
          {
            required: true,
            message: "身份类型不能为空",
            trigger: "blur",
          },
        ],
        merchant_shortname: [
          {
            required: true,
            message: "商户简称不能为空",
            trigger: "blur",
          },
        ], 
        sales_scenes_type: [
          {
            required: true,
            message: "经营场景不能为空",
            trigger: "blur",
          },
        ],       
        settlement_id: [
          {
            required: true,
            message: "结算规则不能为空",
            trigger: "blur",
          },
        ],
        bank_account_type: [
          {
            required: true,
            message: "账户类型不能为空",
            trigger: "blur",
          },
        ],
        id_card_name: [
          {
            required: true,
            message: "法人/受益人",
            trigger: "blur",
          },
        ],
        id_card_number: [
          {
            required: true,
            message: "法人身份证号",
            trigger: "blur",
          },
        ],
        card_period_begin: [
          {
            required: true,
            message: "身份证有效时间",
            trigger: "blur",
          },
        ],
        card_period_end: [
          {
            required: true,
            message: "身份证有效结束时间",
            trigger: "blur",
          },
        ],
        account_name: [
          {
            required: true,
            message: "开户名称不能为空",
            trigger: "blur",
          },
        ],
        bank_branch_id: [
          {
            required: true,
            message: "开户银行全称",
            trigger: "blur",
          },
        ],
        id_card_address: [
          {
            required: true,
            message: "法人居住地址",
            trigger: "blur",
          },
        ],
        account_bank: [
          {
            required: true,
            message: "开户银行不能为空",
            trigger: "blur",
          },
        ],
        bank_address_code: [
          {
            required: true,
            message: "开户省市编码不能为空",
            trigger: "blur",
          },
        ],
        account_number: [
          {
            required: true,
            message: "开户银行卡号不能为空",
            trigger: "blur",
          },
        ],
        contact_id_doc_type:[
          {
            required: true,
            message: "超级管理员证件类型不能为空",
            trigger: "blur",
          },
        ],
        // the_front_of_id_card: [
        //   {
        //     required: true,
        //     message: "身份证正面图片不能为空",
        //     trigger: "change",
        //   },
        // ],
        // reverse_of_id_card: [
        //   {
        //     required: true,
        //     message: "身份证反面图片不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // business_license_img: [
        //   {
        //     required: true,
        //     message: "营业执照图片不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // business_authorization_letter:[
        //   {
        //     required: true,
        //     message: "授权书图片上传不能为空",
        //     trigger: "blur",
        //   },
        // ],
        cellphone: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
        ],
        // radio: [
        //   {
        //     required: true,
        //     message: "支付类型不能为空",
        //     trigger: "blur",
        //   },
        // ],
        roles_id: [
          {
            required: true,
            message: "角色不能为空",
            trigger: "blur",
          }
        ]      
      },
      roleList: [],
      fileList2:[],
      fileList3:[],
      fileList4:[],
      fileList5:[],
      FormData:{}
    });
    onMounted(() => {
      getUserList();
      getRoleList();
    });

    // 用户查询
    const getUserList = () => {
      sel_superuser()
        .then((res) => {
          data.tableData = res.data;
          data.total = res.total;
          data.loading = false;
        })
        .catch(() => {
          data.tableData = [];
          data.loading = false;
        });
    };
    // 用户名禁用
    const isEdit = ref(false);
    // 用户编辑
    const handleEdit = (index, row) => {
      console.log('编辑11111111111');
      // data.radio = row.of_pay?row.of_pay:0;
      data.editUser = JSON.parse(JSON.stringify(row));
      data.isAdd = "0";
      data.DiaLogFlagTitle = "编辑系统运营用户";
      data.addRoleDiaLogFlag = true;
      data.isEdit = true;
      console.log(row,'--=');
    };
    // 关闭弹框
    const handleClose = () => {
      data.editUser = {
        username: "",
        password: "",
        email:"",
        cellphone: "",
        mobile_phone: "",
        id_number: "",
        id_doc_type: "",
        subject_type: "",
        the_front_of_id_card: "",
        reverse_of_id_card: "",
        business_license_img: "",
        license_number: "",
        merchant_name: "",
        legal_person: "",
        contact_name: "",
        contact_id_number: "",
        mobile_phone: "",
        contact_email: "",
        merchant_shortname: "",
        sales_scenes_type: "",
        bank_account_type: "",
        account_name: "",
        account_bank: "",
        id_card_name: "",
        id_card_number: "",
        card_period_begin: "",
        card_period_end: "",
        contact_id_doc_type: "",
        bank_branch_id: "",
        id_card_address: "",
        bank_address_code: "",
        account_number: "",
        business_authorization_letter:"",
        firm_type: "",
        // branch: "",
        // mchid:"",
        is_active: "",
        status: "",
        // of_pay:0
        // department: "",
        // is_active: 1,
      };
      data.editUser2 = {
        // of_pay:0
      }
      data.editUser3 = {
        // of_pay:0
      }
      data.addRoleDiaLogFlag = false;
      data.addRoleDiaLogFlag2 = false;
      data.addRoleDiaLogFlag3 = false;
    };
    // 打开商户认证弹框
    const openAddUser2 = (row) => {
      data.addRoleDiaLogFlag2 = true;
      data.DiaLogFlagTitle2 = "商户入驻认证";
      data.isAdd = "1";
      console.log('999');
      data.editUser2.username = row.username;
      data.editUser2.contact_email = row.email;
      data.editUser2.mobile_phone = row.cellphone;
      data.editUser2.contact_id_number = row.id_number;
      data.isEdit =false;
    };
    // const handleChange = (value) => {
    //   console.log('111111')
    //   console.log(value, 'valuevalue')
    //   data.editUser.of_pay = value
    //   data.editUser2.of_pay = value
    //   data.editUser3.of_pay = value
    //   console.log(data.editUser.of_pay,'data.editUser.of_pay');
    // };
     // 打开添加运营用户弹框
     const openAddUser3 = () => {
      data.addRoleDiaLogFlag3 = true;
      data.isAdd = "1";
      data.DiaLogFlagTitle3 = "新增运营用户";
      data.isEdit = false;
    };
    let formdata = new FormData()
    let formdata2 = new FormData()

    const uploadFile2 = ref(ElUpload);
    const uploadFile3 = ref(ElUpload);
    const uploadFile4 = ref(ElUpload);
    const uploadFile5 = ref(ElUpload);
    const onBeforeAppendFromData3 = (e) =>{
      formdata2.append('business_license_img ',e.file)
    }
    const onBeforeAppendFromData4 = (e) =>{
      formdata2.append('the_front_of_id_card ',e.file)
    }
    const onBeforeAppendFromData5 = (e) =>{
      formdata2.append('reverse_of_id_card ',e.file)
    }
    const onBeforeAppendFromData6 = (e) =>{
      formdata2.append('business_authorization_letter ',e.file)
    }
    // 上传图片校验
    const onBeforeRuls = (rawFile) => {
      console.log("2", rawFile);

      const isImage =
        rawFile.type === "image/png" ||
        rawFile.type === "image/jpeg" ||
        rawFile.type === "image/jpg";
       if (!isImage) {
         ElMessage.error("上传文件格式务必 PNG | JPG");
         return false;
       } else if (rawFile.size / 1024 / 1024 > 10) {
         ElMessage.error("上传文件大小大于10M");
         return false;
      }
    // const onBeforeRuls = (e) => {
    //   console.log("2", e);
      // const fileSize = e.size / 1024 / 1024;
      // if (fileSize > 10) {
      //   data.upLoadRuls.isPass = false;
      //   data.upLoadRuls.message = `文件大小不得超出10M:${e.name}`;
      // }
    };

    
    // 提交添加/编辑
    const submitEditUser = () => {
      formdata.append('username',data.editUser.username)
      formdata.append('password',data.editUser.password)
      formdata.append('email',data.editUser.email)
      formdata.append('id_number', data.editUser.id_number)
      formdata.append('contact_id_number', data.editUser.contact_id_number)
      formdata.append('cellphone', data.editUser.cellphone)
      formdata.append('mobile_phone', data.editUser.mobile_phone)
      // formdata.append('mchid',data.editUser.mchid)
      formdata.append('roles_id',data.editUser.roles_id)
      // formdata.append('person_or_enterprise',0)
      // uploadFile1.value.submit();
      switch (data.isAdd) {
        case "1": {
          editUserDialog.value.validate((valid) => {
            if (valid) {
              const pwd = data.editUser.password;
              data.editUser.password = pwd;
              commercial_tenant(formdata).then((res) => {
                if (res) {
                  getUserList();
                  handleClose();
                  ElMessage({
                    type: "success",
                    message: "添加成功",
                  });
                  data.editUser = {}
                }
              });
            }
          });
          break;
          
        }
        case "0": {
          console.log(data,'系统')
          const pwd = data.editUser.password;
          if (pwd) {
            data.editUser.password = pwd;
          } else {
            data.editUser.password = "";
          }
          edit_superuser(formdata).then((res) => {
            console.log(res,'编辑系统运营用户res')
            if (res) {
              if(res.code===200){
                getUserList();
                handleClose();
              ElMessage({
                type: "success",
                message: "编辑成功",
              });
              data.addRoleDiaLogFlag = false;
              data.editUser = {}
              }else{
                ElMessage.error(res.msg)
              }
            }
          });
          break;
        }
      }
    };

    
     // 提交商户入驻认证
    const submitEditUser2 = () => {
      formdata2.append('username',data.editUser2.username)
      // formdata2.append('password',data.editUser2.password)
      formdata2.append('contact_email',data.editUser2.contact_email)
      // formdata2.append('business_license_number',data.editUser2.business_license_number)
      formdata2.append('id_doc_type', data.editUser2.id_doc_type)
      formdata2.append('subject_type', data.editUser2.subject_type)
      // formdata2.append('cellphone',data.editUser2.cellphone)
      formdata2.append('mobile_phone',data.editUser2.mobile_phone)
      // formdata2.append('mchid', data.editUser2.mchid)
      formdata2.append('license_number', data.editUser2.license_number)
      formdata2.append('merchant_name', data.editUser2.merchant_name)
      formdata2.append('legal_person', data.editUser2.legal_person)
      formdata2.append('contact_name', data.editUser2.contact_name)
      formdata2.append('contact_id_number', data.editUser2.contact_id_number)
      formdata2.append('merchant_shortname', data.editUser2.merchant_shortname)
      formdata2.append('sales_scenes_type', data.editUser2.sales_scenes_type)
      formdata2.append('settlement_id', data.editUser2.settlement_id)
      formdata2.append('bank_account_type', data.editUser2.bank_account_type)
      formdata2.append('account_name', data.editUser2.account_name)
      formdata2.append('account_bank', data.editUser2.account_bank)
      formdata2.append('id_card_name', data.editUser2.id_card_name)
      formdata2.append('id_card_number', data.editUser2.id_card_number)
      formdata2.append('card_period_begin', data.editUser2.card_period_begin)
      formdata2.append('card_period_end', data.editUser2.card_period_end)
      formdata2.append('contact_id_doc_type', data.editUser2.contact_id_doc_type)
      formdata2.append('bank_branch_id', data.editUser2.bank_branch_id)
      formdata2.append('id_card_address', data.editUser2.id_card_address)
      formdata2.append('bank_address_code', data.editUser2.bank_address_code)
      formdata2.append('account_number', data.editUser2.account_number)
      // formdata2.append('person_or_enterprise',1)
      uploadFile2.value.submit();
      uploadFile3.value.submit();
      uploadFile4.value.submit();
      uploadFile5.value.submit();
      switch (data.isAdd) {
        case "1": {
          console.log(editUserDialog1,'editUserDialog1editUserDialog1');
          editUserDialog1.value.validate((valid) => {
            if (valid) {
              const pwd = data.editUser2.password;
              data.editUser2.password = pwd;
              commercial_tenant(formdata2).then((res) => {
                if (res) {
                  getUserList();
                  handleClose();
                  ElMessage({
                    type: "success",
                    message: "认证成功",
                  });
                  data.editUser2 = {}
                }
              });
            }
          });
          break;
          
        }
        case "0": {
          console.log(data,'编辑系统运营用户')
          const pwd = data.editUser2.password;
          if (pwd) {
            data.editUser2.password = pwd;
          } else {
            data.editUser2.password = "";
          }

          console.log(data.editUser2,'00--');
          edit_superuser(formdata2).then((res) => {
            console.log(res,'编辑系统运营用户res')
            // if (res) {
            //   if(res.code===200){
                getUserList();
              // ElMessage({
              //   type: "success",
              //   message: "编辑成功",
              // });
              data.addRoleDiaLogFlag = false;
              data.editUser2 = {}
              // }else{
              //   ElMessage.error(res.msg)
              // }
            // }
          });
          break;
        }
      }
    };
    const submitEditUser3 = () => {
      const dataa = data.editUser3;
      switch (data.isAdd) {
        case "1": {
          console.log('1111');
          console.log(editUserDialog2,'editUserDialog2editUserDialog2');
          editUserDialog2.value.validate((valid) => {
            if (valid) {
              const pwd = data.editUser3.password;
              data.editUser3.password = pwd;
              add_superuser1(dataa).then((res) => {
                if (res) {
                  getUserList();
                  handleClose();
                  ElMessage({
                    type: "success",
                    message: "添加成功",
                  });
                  data.editUser2 = {}
                }else{
                ElMessage.error(res.msg)
              }
              });
            }
          });
          break;
          
        }
        case "0": {
          console.log(data,'编辑系统运营用户')
          const pwd = data.editUser3.password;
          if (pwd) {
            data.editUser3.password = pwd;
          } else {
            data.editUser3.password = "";
          }

          console.log(data.editUser3,'00--');
          edit_superuser(dataa).then((res) => {
            console.log(res,'编辑运营用户res')
            if (res) {
              if(res.code===200){
                getUserList();
                handleClose();
              ElMessage({
                type: "success",
                message: "编辑成功",
              });
              data.addRoleDiaLogFlag = false;
              data.editUser3 = {}
              }else{
                ElMessage.error(res.msg)
              }
            }
          });
          break;
        }
      }
    };
    // 用户删除
    const handleDelete = (index, row) => {
      const dataa = {
        username: row.username,
      };
      ElMessageBox.confirm("您确认要删除系统运营用户吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUser(dataa).then((res) => {
          if (res) {
            getUserList();
            ElMessage({
              type: "success",
              message: "删除成功",
            });
          }
        });
      }).catch(()=>{

      })
    };
 
    const disable = (index, row) => {
      const dataa = {
        username: row.username,
      };
      ElMessageBox.confirm("您确认要禁用系统运营用户吗?", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        prohibit_user(dataa).then((res) => {
          if (res) {
            getUserList();
            ElMessage({
              type: "success",
              message: "禁用成功",
            });
          }
        });
      }).catch(()=>{

      })
    };
    // 启用
    const enable = (index, row) => {
      const dataa = {
        username: row.username,
      };
      ElMessageBox.confirm("您确认要启用系统运营用户吗?", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        active_user(dataa).then((res) => {
          if (res) {
            getUserList();
            ElMessage({
              type: "success",
              message: "启用成功",
            });
          }
          else{
            ElMessage({
              type: "error",
              message: "启用失败"
            });
          }
        });
      }).catch(()=>{

      })
    };
    // 获取角色列表
    const getRoleList = () => {
      roles().then((res) => {
        if (res) {
          data.roleList = res.data;
          console.log(res.data,'0000000')
        }
      });
    };

    const handleCurrentChange = (val)=>{
      data.page = val;
      getUserList()
    }
    return {
      ...toRefs(data),
      getUserList,
      handleEdit,
      handleClose,
      submitEditUser,
      submitEditUser2,
      submitEditUser3,
      handleDelete,
      uploadFile2,
      uploadFile3,
      uploadFile4,
      uploadFile5,
      editUserDialog,
      editUserDialog1,
      editUserDialog2,
      // handleChange,
      disable,
      enable,
      openAddUser2,
      openAddUser3,
      onBeforeRuls,
      onBeforeAppendFromData3,
      onBeforeAppendFromData4,
      onBeforeAppendFromData5,
      onBeforeAppendFromData6,
      handleCurrentChange
    };
  },
};
</script>
